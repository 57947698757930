import React from "react";
// import _get from "lodash.get";

import Image from "app/components/Image";

const GalleryImage = ({ className, image, size }) => (
  <div className={className}>
    <Image
      className={className}
      bgColor="black"
      image={image}
      width={size}
      height={size}
      objectFit="contain"
      objectPosition="50% 50%"
    />
  </div>
);

export default GalleryImage;

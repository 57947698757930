import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { rem, contentWidth } from "app/styles/utils";

export const Wrap = styled.nav`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  width: ${rem(230)};
  height: ${rem(700)};
  padding-top: ${rem(40)};
  justify-content: flex-start;
  align-items: center;
  background: var(--graydark);
  border: 1px solid var(--ivory);
  border-right: 0;
  border-bottom: 0;
  border-radius: ${rem(70)} 0 0 0;
  z-index: 10;

  @media (min-width: ${rem(contentWidth + 157)}) {
    position: fixed;
  }

  ${({ hidden }) => hidden && `display: none;`};
`;

export const WrapH = styled.nav`
  position: absolute;
  left: ${rem(100)};
  right: ${rem(100)};
  bottom: 0;
  display: flex;
  justify-content: space-between;
  z-index: 10;
`;

export const Button = styled.button`
  background: none;
  border: none;
  color: var(--ivory);
  cursor: pointer;

  ${({ off }) => !!off && `opacity: 0.2; pointer-events: none;`}
`;

export const icon = css`
  width: ${rem(200)};
  height: ${rem(200)};
`;

export const next = css`
  width: ${rem(200)};
  height: ${rem(200)};
  transform: rotate(180deg);
`;

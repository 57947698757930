import React from "react";

const Placeholder = ({ width, height, className, style, bgColor }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox={`0 0 ${width} ${height}`}
    className={className}
    style={style}
  >
    <rect
      fill={bgColor ? "currentColor" : "none"}
      width={"100%"}
      height={"100%"}
    />
  </svg>
);

export default Placeholder;

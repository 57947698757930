import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import _get from "lodash.get";

import Placeholder from "app/components/Placeholder";

import * as S from "app/components/Image.style";

const BasicImage = ({ url, image, className }) => {
  return (
    <img src={url || _get(image, "[0].url")} className={className} alt="" />
  );
};

const bgColors = {
  ivory: S.bgIvory,
  black: S.bgBlack,
  grayDark: S.bgGrayDark,
};

const OptimizedImage = ({ bgColor, image, url, width, height, ...props }) => {
  const imageFile = _get(image, `[0].imageFile`);
  const imageData = getImage(imageFile);

  if (!imageData) {
    return <></>;
  }

  return (
    <div
      css={[S.relative, S.wrapper]}
      style={{
        maxWidth: _get(image, `[0].width`),
        maxHeight: _get(image, `[0].height`),
      }}
    >
      <Placeholder
        bgColor={bgColor}
        css={[S.absolute, S.placeholder, _get(bgColors, bgColor)]}
        {...imageData}
        style={{
          maxWidth: width,
          maxHeight: height,
        }}
      />
      <GatsbyImage alt="" {...props} image={imageData} />
    </div>
  );
};

const imageTypes = {
  "image/jpeg": OptimizedImage,
  "image/png": OptimizedImage,
  "image/gif": BasicImage,
};

const Image = ({ bgColor, image, ...props }) => {
  const mimeType = _get(image, `[0].mimeType`);
  const ImageType = _get(imageTypes, mimeType) || OptimizedImage;

  return <ImageType image={image} bgColor={bgColor} {...props} />;
};

export default Image;

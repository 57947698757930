import React from "react";
import IconArrowButton from "app/components/icons/IconArrowButton";
import ChevronArrowButton from "app/components/icons/ChevronArrowButton";

import { blur } from "app/components/Button";

import * as S from "app/components/Pager.style";

const Pager = ({ vert, onPrev, onNext }) => {
  const Wrap = vert ? S.Wrap : S.WrapH;
  const Icon = vert ? IconArrowButton : ChevronArrowButton;

  const prev = typeof onPrev === "function";
  const next = typeof onNext === "function";

  return (
    <Wrap hidden={!prev && !next}>
      <S.Button
        onMouseDown={blur}
        onClick={(prev && onPrev) || undefined}
        off={!prev}
        data-off={!prev}
        data-track-name={"pager-prev"}
      >
        <Icon css={S.icon} />
      </S.Button>

      <S.Button
        onMouseDown={blur}
        onClick={(next && onNext) || undefined}
        off={!next}
        data-track-name={"pager-next"}
      >
        <Icon css={S.next} />
      </S.Button>
    </Wrap>
  );
};

Pager.defaultProps = {
  vert: true,
};

export default Pager;

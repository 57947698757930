import React from "react";
import SVGIcon from "app/components/icons/SVGIcon";

const IconArrowButton = ({ color, bg, ...props }) => (
  <SVGIcon {...props}>
    <g fill="none" fillRule="evenodd" transform="rotate(90 100 100.5)">
      <circle cx="100" cy="100" r="100" style={{ fill: bg }}></circle>
      <path
        fill={color}
        fillRule="nonzero"
        d="M90.752 68.768a1.574 1.574 0 112.226 2.226L65.34 98.63l74.97.001c.82 0 1.494.629 1.567 1.43l.006.144c0 .869-.704 1.573-1.573 1.573H65.478l27.5 27.5c.578.578.612 1.495.102 2.113l-.102.112a1.574 1.574 0 01-2.226 0l-30.119-30.119a1.57 1.57 0 01-.455-1.249 1.57 1.57 0 01.455-1.248z"
      ></path>
    </g>
  </SVGIcon>
);

IconArrowButton.defaultProps = {
  bg: "var(--graydark)",
  color: "currentColor",
  width: "200",
  height: "200",
  viewBox: "0 0 201 201",
};

export default IconArrowButton;

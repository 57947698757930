import { css } from "@emotion/react";

export {
  container,
  flex,
  flexCenterAll,
  flexCol,
  justifyCenter,
  mx0,
  mxauto,
  relative,
  w100,
} from "app/styles/utils";

export const galleryItemPage = css`
  grid-column-start: nav;
  grid-column-end: content;
`;

export const galleryItemRoot = css`
  width: 100%;
  height: 100%;
  padding-top: var(--nav-height);
  display: grid;
  grid-template-columns: repeat(15, 1fr);
  grid-column-gap: 34px;
`;

export const modalWindow = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 800;
  background-color: var(--graydark);
  overflow: auto;
  overflow-x: hidden;
`;

export const imageArea = css`
  position: fixed;
  top: var(--nav-height);
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  grid-template-columns: repeat(15, 1fr);
  grid-column-gap: 34px;
  padding: 45px 0;
  pointer-events: none;
`;

export const imageBox = css`
  grid-column: 1 / 9;
  height: calc(100vh - var(--nav-height) - 90px);
  pointer-events: auto;
`;

export const pagerBox = css`
  position: absolute;
  bottom: 0;
  left: 0%;
  width: 100%;
`;

export const contentArea = css`
  grid-column: 9 / -1;
  padding: 45px 0;
  height: 100%;
`;

export const contentBox = css`
  width: 100%;
  max-width: 490px;
`;

export const image = css`
  height: 100%;
  width: 100%;
`;

export const placeholder = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

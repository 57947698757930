import React from "react";
import SVGIcon from "app/components/icons/SVGIcon";

const ChevronArrowButton = ({ color, bg, ...props }) => (
  <SVGIcon {...props}>
    <g fill="none" fillRule="evenodd" transform="translate(1 1)">
      <circle
        cx="100"
        cy="100"
        r="100"
        style={{ fill: bg }}
        stroke={color}
      ></circle>
      <path
        fill={color}
        d="M118.915 65.769a1.82 1.82 0 01-.09 2.572L84.875 100l33.95 31.659a1.82 1.82 0 11-2.482 2.662l-35.168-32.794a1.817 1.817 0 01-.569-1.527c-.059-.55.133-1.12.569-1.527l35.168-32.794a1.82 1.82 0 012.572.09z"
      ></path>
    </g>
  </SVGIcon>
);

ChevronArrowButton.defaultProps = {
  bg: "var(--graydark)",
  color: "currentColor",
  width: "200",
  height: "200",
  viewBox: "0 0 202 202",
};

export default ChevronArrowButton;
